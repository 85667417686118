import React from "react"
import styled from "styled-components"
const TextEditorBlock = ({ content, heading, blockId, line }) => {
  return (
    <TextEditor
      id={blockId}
      className={
        line === "yes"
          ? "tpl-block has-line text-block   pb-6"
          : "text-block   pb-3"
      }
    >
      <div className="container lg:px-2 px-4 mx-auto">
        {heading ? <h2 className="title mb-4">{heading}</h2> : null}
        {content ? (
          <div
            className="title-block-text"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        ) : null}
      </div>
    </TextEditor>
  )
}

export default TextEditorBlock
const TextEditor = styled.section`
  iframe {
    width: 100%;
    min-height: 500px;
  }
  h4 {
    border-bottom: 2px solid;
    padding-bottom: 2.4rem;
    font-weight: 700;
  }
  p + h4 {
    margin-top: 4rem;
  }
  ul {
    // list-style: inside;
    li + li {
      margin-top: 0.5rem;
    }
  }
`
